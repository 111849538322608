<template>
  <div class="min-vh-100">
    <div class="branding">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandingIndex",
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.branding {
  display: flex;
  min-height: 100vh;
  padding: 44px 30px;

  /deep/ .loading-zone {
    width: 100%;
  }

  @media (max-width: 676px) {
    display: block;
  }
}
</style>
