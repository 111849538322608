<template>
  <nav class="tabs">
    <router-link
      v-for="route in routes"
      :key="route.name"
      class="tabs__link"
      :to="route"
    >
      {{ route.text }}
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  height: 58px;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding: 0 30px;

  &__link {
    display: block;
    padding: 19px 0;
    font-size: 13px;
    color: #000000;
    border-bottom: 2px solid #ffffff00;
    width: 87px;
    text-align: center;

    &.router-link-exact-active {
      font-weight: 600;
      border-bottom: 2px solid #00a8f8;
    }

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
