<template>
  <div class="widget-modal">
    <h2 class="widget-modal__title">
      {{ $t("c.channels.widget.send_modal_title") }}
    </h2>
    <p class="widget-modal__description">
      {{ $t("c.channels.widget.send_modal_description") }}
    </p>
    <FormControl
      class="widget-modal__form-control"
      :label="$t('c.channels.widget.email_recipient_label')"
      :error="errorMessageEmail"
    >
      <Input
        v-model="email"
        type="email"
        :placeholder="$t('c.channels.widget.email_placeholder')"
        :has-errors="errorMessageEmail ? true : false"
      />
    </FormControl>
    <FormControl
      class="widget-modal__message-control"
      :label="$t('c.channels.widget.message_label')"
    >
      <div class="widget-modal__message">
        <p>
          {{ $t("c.channels.widget.email_title") }}
        </p>
        <h3>{{ $t("c.channels.common.step", { num: 1 }) }}</h3>
        <p>
          {{ $t("c.channels.widget.modal_step_desctiption") }}
        </p>
        <div class="widget-modal__code">
          {{ code }}
        </div>
        <h3>{{ $t("c.channels.common.step", { num: 2 }) }}</h3>
        <p>
          {{ $t("c.channels.widget.third_step_desctiption") }}
        </p>
        <div class="widget-modal__code">
          {{ anchor }}
        </div>
        <p class="widget-modal__ending">
          {{ $t("c.channels.widget.email_ending") }}
        </p>
        <p>{{ $t("c.channels.widget.email_signature") }}</p>
      </div>
    </FormControl>
    <Button
      class="widget-modal__send-email"
      data-test="send"
      :is-loading="loading"
      @click="sendInstallationEmail"
    >
      {{ $t("c.channels.widget.modal_send_button") }}
    </Button>
    <Button kind="secondary" data-test="cancel" @click="$emit('close')">
      {{ $t("c.channels.widget.modal_cancel_button") }}
    </Button>
  </div>
</template>

<script>
import SEND_WIDGET_INSTALLATION_EMAIL from "@/modules/sales-channels/graphql/SendWidgetInstallationEmail.gql";
import { FormControl, Input, Button, Toast } from "@ds";
import { pathOr, has } from "ramda";

export default {
  components: {
    FormControl,
    Input,
    Button,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    anchor: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    email: "",
    errorMessageEmail: null,
    loading: false,
  }),
  methods: {
    async sendInstallationEmail() {
      this.errorMessageEmail = null;
      this.loading = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: SEND_WIDGET_INSTALLATION_EMAIL,
          variables: {
            businessId: this.businessId,
            email: this.email,
          },
        });

        if (has("errors", response)) {
          throw response.errors;
        }

        new Toast().create({
          type: "success",
          title: this.$t("c.channels.widget.send_email"),
        });

        this.$emit("close");
      } catch (errors) {
        const validationErrors = pathOr(
          [null],
          ["0", "extensions", "validation", "email"],
          errors
        );

        this.errorMessageEmail = validationErrors[0];

        if (!this.errorMessageEmail) {
          new Toast().create({
            type: "error",
            title: this.$t("label.oops"),
            text: this.$t("message.general_failure"),
          });

          throw errors;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-modal {
  padding: 30px;

  &__title {
    font-weight: 600;
    font-size: 17px;
    color: #444444;
  }

  &__description {
    font-size: 14px;
    color: #84a0b2;
    margin-bottom: 25px;
  }

  &__form-control {
    width: 320px;
    margin-bottom: 20px;
  }

  &__message {
    border: 1px solid #dee2e6;
    padding: 11px 16px;
    border-radius: 4px;
    height: 272px;
    overflow-y: auto;

    h3 {
      font-size: 14px;
      font-weight: 700;
    }

    p {
      font-size: 13px;
    }
  }

  &__message-control {
    margin-bottom: 20px;
  }

  &__code {
    font-size: 13px;
    white-space: pre;
    tab-size: 4;
    line-height: 200%;
    font-family: "Cutive Mono", monospace;
    letter-spacing: -0.1em;
    margin-bottom: 15px;
  }

  &__send-email {
    margin-right: 17px;
  }

  &__ending {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
