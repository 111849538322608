<template>
  <div class="clearfix">
    <div class="py-5 p-10 mx-auto" style="max-width: 550px">
      <div class="title d-flex align-items-center m_bottom_24">
        <Icon icon="instagram" :size="40" />
        <h4 class="m-0 p_left_16">
          {{ $t("c.channels.instagram_connect.title") }}
        </h4>
      </div>
      <div class="description m_bottom_18">
        {{ $t("c.channels.instagram_connect.description") }}
      </div>

      <h5 class="m_bottom_16">
        {{ $t("c.channels.common.step", { num: 1 }) }}
      </h5>
      <div class="m_bottom_30">
        {{ $t("c.channels.instagram_connect.username_instruction") }}
      </div>
      <Input
        v-model="salesChannel.username"
        :disabled="status === 'COMPLETE'"
        class="m_bottom_30"
        type="text"
        minlength="3"
        :placeholder="$t('c.channels.instagram_connect.username_placeholder')"
      />

      <h5 class="m_bottom_16">
        {{ $t("c.channels.common.step", { num: 2 }) }}
      </h5>
      <!-- eslint-disable -->
      <div
        class="m_bottom_30"
        v-html="$t('c.channels.instagram_connect.edit_profile_instruction')"
      ></div>
      <!-- eslint-enable -->
      <div class="image-wrapper m_top_32 m_bottom_32">
        <img
          class="w-100"
          src="@/modules/sales-channels/assets/img/instagram-instruction-1.png"
          alt="Instagram Instruction"
        />
      </div>

      <h5 class="m_bottom_16">
        {{ $t("c.channels.common.step", { num: 3 }) }}
      </h5>
      <!-- eslint-disable -->
      <div
        class="m_bottom_16"
        v-html="$t('c.channels.instagram_connect.website_add_instruction')"
      ></div>
      <!-- eslint-enable -->
      <LinkBox :link="orderUrl" />
      <div class="image-wrapper m_top_32 m_bottom_32">
        <img
          class="w-100"
          src="@/modules/sales-channels/assets/img/instagram-instruction-1.png"
          alt="Instagram Instruction"
        />
      </div>
      <HelpFeedback
        :business-id="currentBusinessId"
        :business-name="businessName"
        :channel="channels.INSTAGRAM"
      />
      <hr />

      <div class="d-flex flex-column justify-content-center align-items-center">
        <template v-if="verificationInProgress">
          <Icon icon="loading" :size="22" />
        </template>
        <VerificationButton
          v-else
          :status="status"
          :channel="channels.INSTAGRAM"
          :on-click="verifyUpdatedUsername"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@ds";
import VerificationButton from "@/modules/sales-channels/ui/components/Button/VerificationButton.vue";
import GET_SALES_CHANNEL from "@/modules/sales-channels/graphql/GetSalesChannel.gql";
import UPDATE_INSTAGRAM_USERNAME from "@/modules/sales-channels/graphql/UpdateInstagramUsername.gql";
import VERIFY_SALES_CHANNEL from "@/modules/sales-channels/graphql/VerifySalesChannel.gql";
import LinkBox from "@/modules/sales-channels/ui/components/LinkBox.vue";
import HelpFeedback from "@/modules/sales-channels/ui/components/HelpFeedback.vue";
import { Toast, Input } from "@ds";
import { mapGetters } from "vuex";
import { find, propOr, has } from "ramda";
import { channels, statuses } from "@/utils/enums/channels";
import { SALES_CHANNEL_VERIFIED } from "@/plugins/analytics/events";

export default {
  name: "Instagram",
  components: {
    Icon,
    LinkBox,
    VerificationButton,
    HelpFeedback,
    Input,
  },
  data: () => ({
    salesChannel: {
      initalValue: null,
      username: null,
      verificationStatus: null,
    },
    verificationInProgress: false,
    verificationError: null,
    channels,
  }),
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    orderUrl() {
      return `${process.env.VUE_APP_WEBVIEW_URL}/i/${this.currentBusinessUrl}`;
    },
    clipUrl() {
      return `${process.env.VUE_APP_CLIP_URL}/i/${this.currentBusinessUrl}`;
    },
    validLinks() {
      return [this.orderUrl, this.clipUrl];
    },
    businessName() {
      return propOr("", "name", this.currentBusiness);
    },
    isUsernameEdited() {
      return this.salesChannel.initalValue !== this.salesChannel.username;
    },
    isUsernameEmpty() {
      return !this.salesChannel.username;
    },
    currentBusinessId() {
      return this.$route.params.businessId;
    },
    currentBusinessUrl() {
      return this.currentBusiness?.url ?? "";
    },
    status() {
      return this.salesChannel.verificationStatus ?? statuses.INACTIVE;
    },
    currentBusiness() {
      return find((obj) => obj.id === this.currentBusinessId, this.businesses);
    },
  },
  methods: {
    async verifyUpdatedUsername() {
      this.verificationInProgress = true;

      if (!this.salesChannel.username) {
        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("c.channels.instagram_connect.username_error"),
        });
        this.verificationInProgress = false;
        return;
      }

      try {
        const updateUsernameResult = await this.$apollo.mutate({
          mutation: UPDATE_INSTAGRAM_USERNAME,
          variables: {
            businessId: this.currentBusinessId,
            username: this.salesChannel.username,
          },
        });

        const usernameUpdateSuccess =
          updateUsernameResult?.data?.updateInstagramUsername;

        if (usernameUpdateSuccess === false) {
          throw new Error("USERNAME_UPDATE_FAILED");
        }

        const res = await this.$apollo.mutate({
          mutation: VERIFY_SALES_CHANNEL,
          variables: {
            businessId: this.currentBusinessId,
            salesChannelEnum: channels.INSTAGRAM,
          },
        });

        if (has("errors", res)) throw res.errors;

        this.$analytics.trackEvent(SALES_CHANNEL_VERIFIED, {
          businessId: this.currentBusinessId,
          businessName: this.businessName,
          channel: channels.INSTAGRAM,
        });

        new Toast().create({
          type: "success",
          tite: this.$t("label.success"),
          text: this.$t("c.channels.common.verification_success_message"),
        });

        this.$apollo.queries.salesChannel.refetch();
      } catch (error) {
        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("c.channels.common.verification_error_message"),
        });
      } finally {
        this.verificationInProgress = false;
      }
    },
  },
  apollo: {
    salesChannel: {
      query: GET_SALES_CHANNEL,
      variables() {
        return {
          businessId: this.currentBusinessId,
          salesChannelEnum: channels.INSTAGRAM,
        };
      },
      update(response) {
        const channel = response.business.salesChannels[0];
        return {
          initalValue: channel.username,
          username: channel.username,
          verificationStatus: channel.state,
        };
      },
    },
  },
};
</script>
