<template>
  <div class="row no-gutters m_top_54 align-items-center">
    <div class="col-md centralize">
      <a href="" @click.prevent="$intercom.show()">{{
        $t("c.channels.common.helpbox.need_help")
      }}</a>
    </div>
    <div class="col-md centralize">
      {{ $t("c.channels.common.helpbox.was_helpful") }}
    </div>
    <div class="col-md btn-wrapper centralize">
      <button
        :class="selectedBtnClass(submittedYes)"
        :disabled="submittedNo"
        @click.once="onClickFeedback(true)"
      >
        {{ $t("label.yes") }}
      </button>
      <button
        :class="selectedBtnClass(submittedNo)"
        :disabled="submittedYes"
        @click.once="onClickFeedback(false)"
      >
        {{ $t("label.no") }}
      </button>
    </div>
  </div>
</template>

<script>
import { Toast } from "@ds";
import { SALES_CHANNEL_HELP_FEEDBACK } from "@/plugins/analytics/events";

export default {
  name: "HelpFeedback",
  props: {
    businessId: {
      type: String,
      required: true,
    },
    businessName: {
      type: String,
      required: true,
    },
    channel: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    submittedNo: false,
    submittedYes: false,
  }),

  methods: {
    onClickFeedback(feedback) {
      this.$analytics.trackEvent(SALES_CHANNEL_HELP_FEEDBACK, {
        businessId: this.businessId,
        businessName: this.businessName,
        channel: this.channel,
        feedback,
      });

      new Toast().create({
        type: "success",
        title: this.$t("c.channels.common.helpbox.helpfulness_feedback_title"),
      });

      if (feedback) {
        this.submittedYes = true;
        return;
      }
      this.submittedNo = true;
    },
    selectedBtnClass(feedbackBtn) {
      let selectedClass = ["btn", "btn-blue"];
      if (feedbackBtn) {
        selectedClass.push("feedback-sent");
      }
      return selectedClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .centralize {
    display: flex;
    justify-content: center;
  }
}

.btn-blue {
  padding: 8px 19px;
  color: #00a8f8;
  background: rgba(0, 168, 248, 0.1);

  &:disabled {
    cursor: no-drop;
  }

  &.feedback-sent {
    border-color: #dcecf3;
    background: #d5e2e7;
    cursor: no-drop;
  }
}
</style>
