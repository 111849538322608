<template>
  <a class="channel-card" href="#" @click.prevent="openChannel">
    <div class="row text-center text-lg-left">
      <div class="col-lg-auto mb-3">
        <Icon :icon="icon" :size="40" />
      </div>
      <div class="col-lg p-0">
        <div class="row">
          <div class="col-xl mb-3">
            <h5 class="text-capitalize mb-1">
              {{ title }}
            </h5>
          </div>
          <div v-if="status" class="col-xl-auto mb-3">
            <StatusBadge :status="status" />
          </div>
        </div>
      </div>
    </div>
    <p class="channel-card__description">
      {{ description }}
    </p>
    <i18n
      v-if="helperWebsite"
      data-test="help-website"
      class="channel-card__helper"
      path="c.channels.common.help_website"
      @click.stop
    >
      <Anchor modal link="https://chatfood.typeform.com/to/fb3924">
        {{ $t("c.channels.common.help_website_link") }}
      </Anchor>
    </i18n>
  </a>
</template>

<script>
import { Icon, Anchor } from "@/design-system";
import StatusBadge from "@/modules/sales-channels/ui/components/StatusBadge.vue";
import PrerequisiteModal from "./PrerequisiteModal.vue";
import { userChoices } from "@/composable/userChoices";
import { statuses } from "@/utils/enums/channels";

export default {
  name: "ChannelCard",
  components: {
    Icon,
    Anchor,
    StatusBadge,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    helperWebsite: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: null,
      validator: (val) => statuses.includes(val),
    },
    description: {
      type: String,
      default: "",
    },
  },
  computed: {
    skipPrerequisites() {
      return (
        userChoices(
          this.businessId
        ).hasAcknowledgedSalesChannelsPrerequisites() ||
        this.status === statuses.COMPLETE
      );
    },
  },
  methods: {
    openChannel() {
      if (this.skipPrerequisites) {
        this.$router.push({ name: this.routeName });
        return;
      }

      this.$modal.show(
        PrerequisiteModal,
        {
          businessId: this.businessId,
          routeName: this.routeName,
        },
        {
          name: "prerequisite",
          adaptive: true,
          resizable: true,
          height: "auto",
          clickToClose: false,
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.channel-card {
  display: block;
  height: 100%;
  color: inherit;
  padding: 1.5rem;
  text-decoration: none;
  border-radius: 0.25rem;
  border: 1px solid #dee2e6;

  &__description {
    font-size: 14px;
    color: #444;
    margin: 0;
  }

  &__helper {
    display: block;
    font-size: 13px;
    color: #89a0b1;
    padding-top: 10px;

    a {
      color: #89a0b1;
      text-decoration: underline;
    }
  }
}
</style>
