<template>
  <div class="content-body">
    <div class="p_top_50 p_bottom_50 mx-auto">
      <div class="title d-flex align-items-center m_bottom_24">
        <Icon icon="google" :size="40" />
        <h4 class="m-0 p_left_16">
          {{ $t("c.channels.gmb_connect.title") }}
        </h4>
      </div>
      <div class="description m_bottom_18">
        {{ $t("c.channels.gmb_connect.description") }}
      </div>
      <div class="step">
        <h5 class="heading">
          {{ $t("c.channels.common.step", { num: 1 }) }}
        </h5>
        <!-- eslint-disable-next-line -->
        <div v-html="$t('c.channels.gmb_connect.step1_description')"></div>
      </div>

      <div class="image-wrapper">
        <img
          src="@/modules/sales-channels/assets/img/googlemybusiness-instruction-1.png"
          alt="GoogleMyBusiness Instruction"
        />
      </div>

      <div class="step">
        <h5 class="heading">
          {{ $t("c.channels.common.step", { num: 2 }) }}
        </h5>
        <!-- eslint-disable-next-line -->
        <div v-html="$t('c.channels.gmb_connect.step2_description')"></div>
      </div>

      <div class="image-wrapper">
        <img
          src="@/modules/sales-channels/assets/img/googlemybusiness-instruction-2.png"
          alt="GoogleMyBusiness Instruction"
        />
      </div>

      <div class="step">
        <h5 class="heading">
          {{ $t("c.channels.common.step", { num: 3 }) }}
        </h5>
        <!-- eslint-disable -->
        <div
          v-html="
            $t('c.channels.gmb_connect.step3_description', { url: orderUrl })
          "
        ></div>
        <!-- eslint-enable -->
      </div>

      <LinkBox :link="orderUrl" class="m_bottom_32" />

      <div class="image-wrapper">
        <img
          src="@/modules/sales-channels/assets/img/googlemybusiness-instruction-3.png"
          alt="GoogleMyBusiness Instruction"
        />
      </div>

      <HelpFeedback
        :business-id="currentBusinessId"
        :business-name="businessName"
        :channel="channels.GOOGLEMYBUSINESS"
        class="m_bottom_48"
      />

      <hr class="m_bottom_48" />

      <div class="d-flex justify-content-center">
        <VerificationButton
          :status="status"
          :channel="channels.GOOGLEMYBUSINESS"
          :on-click="updateState"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Toast } from "@ds";
import { mapGetters } from "vuex";
import { find, has, propOr } from "ramda";
import LinkBox from "@/modules/sales-channels/ui/components/LinkBox.vue";
import VerificationButton from "@/modules/sales-channels/ui/components/Button/VerificationButton.vue";
import HelpFeedback from "@/modules/sales-channels/ui/components/HelpFeedback.vue";
import GET_SALES_CHANNEL from "@/modules/sales-channels/graphql/GetSalesChannel.gql";
import VERIFY_SALES_CHANNEL from "@/modules/sales-channels/graphql/VerifySalesChannel.gql";
import { channels, statuses } from "@/utils/enums/channels";

export default {
  name: "GoogleMyBusiness",
  components: {
    Icon,
    LinkBox,
    VerificationButton,
    HelpFeedback,
  },
  data: () => ({
    salesChannel: {
      username: null,
      verificationStatus: null,
    },
    channels,
    statuses,
  }),
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    orderUrl() {
      return `${process.env.VUE_APP_WEBVIEW_URL}/g/${propOr(
        "",
        "url",
        this.currentBusiness
      )}`;
    },
    businessName() {
      return propOr("", "name", this.currentBusiness);
    },
    status() {
      return this.salesChannel.verificationStatus ?? statuses.INACTIVE;
    },
    currentBusinessId() {
      return this.$route.params.businessId;
    },
    currentBusiness() {
      return find((obj) => obj.id === this.currentBusinessId, this.businesses);
    },
  },
  methods: {
    async updateState() {
      try {
        const UpdateSalesChannelState = {
          mutation: VERIFY_SALES_CHANNEL,
          variables: {
            businessId: this.currentBusinessId,
            salesChannelEnum: channels.GOOGLEMYBUSINESS,
          },
        };

        const res = await this.$apollo.mutate(UpdateSalesChannelState);

        if (has("errors", res)) throw res.errors;

        new Toast().create({
          type: "success",
          tite: this.$t("label.success"),
          text: this.$t("c.channels.common.verification_success_message"),
        });

        this.$apollo.queries.salesChannel.refetch();
      } catch (error) {
        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("c.channels.common.verification_error_message"),
        });
      }
    },
  },
  apollo: {
    salesChannel: {
      query: GET_SALES_CHANNEL,
      variables() {
        return {
          businessId: this.currentBusinessId,
          salesChannelEnum: channels.GOOGLEMYBUSINESS,
        };
      },
      update(response) {
        const channel = response.business.salesChannels[0];
        return {
          orderingLink: channel.orderingLink,
          username: channel.username,
          verificationStatus: channel.state,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.step {
  margin-bottom: 32px;

  .heading {
    margin-bottom: 16px;
  }
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.content-body {
  margin: 0 auto;
  max-width: 540px;
  padding: 44px 30px;
}
</style>
