<template>
  <div class="py-5 px-4 mx-auto" style="max-width: 550px">
    <div class="d-flex align-items-center mb-4">
      <Icon icon="instagram" :size="40" />
      <h4 class="m-0 p_left_16">
        {{ $t("c.channels.instagram_connect.food_button.title") }}
      </h4>
    </div>
    <p>
      {{ $t("c.channels.instagram_connect.food_button.description") }}
    </p>
    <h5 class="mt-4">
      {{ $t("c.channels.common.step", { num: 1 }) }}
    </h5>
    <p>
      {{ $t("c.channels.instagram_connect.food_button.step1_description") }}
    </p>
    <img
      class="img-fluid"
      src="@/modules/sales-channels/assets/img/instagram-food-button-1.png"
      alt="Instagram Order Food Button - Step 01"
    />
    <h5 class="mt-4">
      {{ $t("c.channels.common.step", { num: 2 }) }}
    </h5>
    <!-- eslint-disable -->
    <div
      class="mb-3"
      v-html="$t('c.channels.instagram_connect.food_button.step2_description')"
    ></div>
    <!-- eslint-enable -->
    <img
      class="img-fluid"
      src="@/modules/sales-channels/assets/img/instagram-food-button-2.png"
      alt="Instagram Order Food Button - Step 02"
    />
    <h5 class="mt-4">
      {{ $t("c.channels.common.step", { num: 3 }) }}
    </h5>
    <!-- eslint-disable -->
    <div
      class="mb-3"
      v-html="$t('c.channels.instagram_connect.food_button.step3_description')"
    ></div>
    <!-- eslint-enable -->
    <LinkBox :link="orderUrl" />
    <img
      class="img-fluid mt-3"
      src="@/modules/sales-channels/assets/img/instagram-food-button-3.png"
      alt="Instagram Order Food Button - Step 03"
    />
    <HelpFeedback
      :business-id="currentBusinessId"
      :business-name="businessName"
      :channel="channels.INSTAGRAM"
    />
  </div>
</template>

<script>
import LinkBox from "@/modules/sales-channels/ui/components/LinkBox.vue";
import HelpFeedback from "@/modules/sales-channels/ui/components/HelpFeedback.vue";
import { Icon } from "@/design-system";
import { mapGetters } from "vuex";
import { find, propOr } from "ramda";
import { channels } from "@/utils/enums/channels";

export default {
  name: "OrderFoodButton",
  components: {
    Icon,
    LinkBox,
    HelpFeedback,
  },
  data: () => ({
    channels,
  }),
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    orderUrl() {
      return `${process.env.VUE_APP_WEBVIEW_URL}/i/${this.currentBusinessUrl}`;
    },
    businessName() {
      return propOr("", "name", this.currentBusiness);
    },
    currentBusinessId() {
      return this.$route.params.businessId;
    },
    currentBusinessUrl() {
      return this.currentBusiness?.url ?? "";
    },
    currentBusiness() {
      return find((obj) => obj.id === this.currentBusinessId, this.businesses);
    },
  },
};
</script>
