<template>
  <div class="preview">
    <div class="preview__body">
      <div class="preview__element">
        <h2>{{ $t("c.channels.widget.preview_title") }}</h2>
        <div class="preview__menu">
          <div
            v-if="imageUrl"
            class="preview__cover"
            :style="`background-image: url(${imageUrl})`"
          ></div>
          <div class="preview__menu-info" :class="{ 'miss-cover': !imageUrl }">
            <div class="preview__menu-block">
              <h4>{{ activeBusiness.name }}</h4>
              <div class="line line--l m-b-5"></div>
              <div class="in-line">
                <div
                  class="dot m-r-4"
                  :style="{ backgroundColor: primaryColor }"
                ></div>
                <div class="line line--xs"></div>
                <div
                  class="dot m-r-4 m-l-10"
                  :style="{ backgroundColor: primaryColor }"
                ></div>
                <div class="line line--xs"></div>
              </div>
              <div class="in-line m-t-10">
                <div
                  class="preview__icon"
                  :style="{ backgroundColor: primaryLightColor }"
                >
                  <Icon icon="diamond" :color="primaryColor" :size="11" />
                </div>
                <div>
                  <div class="line line--l line--dark m-b-3"></div>
                  <div class="line line--m"></div>
                </div>
              </div>
            </div>
            <div class="preview__menu-block in-line-between">
              <div>
                <div class="line line--s line--dark m-b-5"></div>
                <div class="in-line">
                  <div
                    class="dot m-r-4"
                    :style="{ backgroundColor: primaryColor }"
                  ></div>
                  <div class="line line--m"></div>
                </div>
              </div>
              <div
                class="preview__delivery-switcher"
                :style="{ borderColor: primaryColor }"
              >
                <div
                  :style="{
                    color: primaryColor,
                  }"
                >
                  {{ $t("c.channels.widget.preview_delivery") }}
                </div>
                <div
                  :style="{
                    backgroundColor: primaryColor,
                    color: secondaryColor,
                  }"
                >
                  {{ $t("c.channels.widget.preview_pickup") }}
                </div>
              </div>
            </div>
            <div class="preview__menu-block in-line">
              <div
                class="preview__category-label"
                :style="{
                  borderColor: primaryColor,
                  backgroundColor: primaryColor,
                  color: secondaryColor,
                }"
              >
                {{ $t("c.channels.widget.preview_category") }}
              </div>
              <div class="preview__category-label">
                {{ $t("c.channels.widget.preview_category") }}
              </div>
              <div class="preview__category-label">
                {{ $t("c.channels.widget.preview_category") }}
              </div>
              <div class="preview__category-label">
                {{ $t("c.channels.widget.preview_category") }}
              </div>
            </div>
            <div class="preview__menu-block container">
              <div class="line line--m line--dark m-b-10"></div>
              <div
                v-for="item in 4"
                :key="item"
                :class="{ 'in-line-between m-b-10': true, half: !isListLayout }"
              >
                <div>
                  <div
                    v-if="!isListLayout"
                    class="square square--full m-b-5"
                  ></div>
                  <div class="line line--l line--dark m-b-5"></div>
                  <div class="line line--xl m-b-5"></div>
                  <div class="line line--xl m-b-5"></div>
                  <div class="line line--s line--dark"></div>
                </div>
                <div v-if="isListLayout" class="square"></div>
              </div>
              <template v-if="isListLayout">
                <div class="in-line-between m-b-10">
                  <div>
                    <div class="line line--l line--dark m-b-5"></div>
                    <div class="line line--xl m-b-5"></div>
                    <div class="line line--xl m-b-5"></div>
                    <div class="line line--s line--dark"></div>
                  </div>
                  <div class="square"></div>
                </div>
                <div class="in-line-between">
                  <div>
                    <div class="line line--l line--dark m-b-5"></div>
                    <div class="line line--xl m-b-5"></div>
                    <div class="line line--xl m-b-5"></div>
                    <div class="line line--s line--dark"></div>
                  </div>
                  <div class="square"></div>
                </div>
              </template>
              <div class="preview__menu-action">
                <div
                  class="preview__add-item"
                  :style="{
                    backgroundColor: primaryColor,
                    color: secondaryColor,
                  }"
                >
                  {{ $t("c.channels.widget.preview_basket_action") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="preview__element">
        <h2>{{ $t("c.channels.widget.preview_popup_title") }}</h2>
        <div v-if="showBubble" class="preview__widget-body">
          <div class="preview__widget">
            <Icon class="preview__close" icon="cross-widget" />
            <h3 class="preview__headline">{{ headline }}</h3>
            <p class="preview__message">{{ message }}</p>
            <div class="preview__bottom">
              <button
                :style="{
                  color: secondaryColor,
                  backgroundColor: primaryColor,
                  borderRadius,
                }"
              >
                {{ buttonText }}
              </button>
              <span class="preview__powered">
                We’re ⚡️ by
                <span class="preview__powered-chatfood">ChatFood</span>
              </span>
            </div>
          </div>
          <div class="preview__button">
            <img v-if="iconUrl" class="preview__image" :src="iconUrl" />
            <Icon
              v-else
              class="preview__image-default"
              icon="chatfood-logo"
              color="#1258FF"
              :size="31"
            />
          </div>
        </div>
        <div v-else class="preview__widget-body">
          <button
            class="preview__order-button"
            :style="{
              color: secondaryColor,
              backgroundColor: primaryColor,
              borderRadius,
            }"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@ds";
import { widgetShape, widgetStyle } from "@/utils/enums/widget";
import { getActiveBusiness } from "@/utils/mixins";
import { MenuDisplayLayoutEnum } from "@/v2/enum/menu-display-layout-enum";

export default {
  components: {
    Icon,
  },
  mixins: [getActiveBusiness],
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    shape: {
      type: String,
      required: true,
    },
    generalStyle: {
      type: String,
      required: true,
    },
    primaryColor: {
      type: String,
      required: true,
    },
    secondaryColor: {
      type: String,
      required: true,
    },
    primaryLightColor: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    iconUrl: {
      type: String,
      default: null,
    },
    coverUrl: {
      type: String,
      default: null,
    },
    displayLayout: {
      type: String,
      required: true,
      default: MenuDisplayLayoutEnum.LIST,
    },
  },
  computed: {
    borderRadius() {
      switch (this.shape) {
        case widgetShape.ROUNDED_EDGES:
          return "5px";
        case widgetShape.SQUARE:
          return 0;
        case widgetShape.CIRCLE:
          return "100px";
        default:
          return 0;
      }
    },
    showBubble() {
      return this.generalStyle == widgetStyle.BUBBLE_ICON;
    },
    imageUrl() {
      if (!this.coverUrl) return "";

      const imagePath = process.env.VUE_APP_S3_BUCKET_PATH;
      return `${imagePath}${this.coverUrl}?w=248&h=139&fit=crop&crop=center`;
    },
    isListLayout() {
      return this.displayLayout === MenuDisplayLayoutEnum.LIST;
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  border-radius: 4px;
  margin-left: 30px;
  display: flex;

  &__body {
    width: 526px;
    margin: 0 auto;
  }

  @media (max-width: 1000px) {
    margin-left: 0;
    margin-top: 40px;
    width: 100%;

    &__body {
      width: 100%;
    }
  }

  &__element {
    background: rgba(226, 234, 239, 0.2);
    margin-bottom: 1px;
    padding: 20px;

    h2 {
      font-size: 17px;
    }
  }

  &__menu {
    border: 10px solid #dedede;
    border-radius: 10px;
    width: 268px;
    background: #dedede;
    margin: 20px auto;
  }

  &__cover {
    padding-bottom: 56%;
    width: 248px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-size: cover;
  }

  &__menu-info {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: white;

    h4 {
      font-size: 14px;
      color: #000000;
      margin-bottom: 5px;
    }

    &.miss-cover {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &__menu-block {
    padding: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid #f5f5f5;
    }

    &.container {
      max-height: 16.25rem;
      overflow: hidden;
      position: relative;
    }
  }

  &__menu-action {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.625rem;
  }

  &__delivery-switcher {
    border: 1px solid;
    border-radius: 2px;
    font-size: 8px;
    display: flex;

    div {
      padding: 2px 4px;
      color: #ffffff;
    }
  }

  &__category-label {
    border-radius: 9px;
    color: rgb(221, 221, 221);
    border: 1px solid rgb(221, 221, 221);
    padding: 2px 7px 2px;
    font-size: 8px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  &__icon {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__widget {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 14px 15px;
    border-radius: 15px;
    border-bottom-right-radius: 0;
    position: relative;
    width: 289px;
  }

  &__widget-body {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: flex-end;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  &__headline {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: #444444;
  }

  &__message {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #444444;
    margin-top: 7px;
  }

  &__bottom {
    margin-top: 17px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    button {
      padding: 4px 13px;
      position: relative;
      border: none;
      font-size: 16px;
      border-radius: 4px;
    }
  }

  &__powered {
    font-size: 8px;
    color: #444444;
  }

  &__powered-chatfood {
    color: #00a8f8;
  }

  &__button {
    margin-left: 9px;
    width: 54px;
    height: 54px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 11px 13px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    max-width: 31px;
    max-height: 31px;
  }

  &__order-button {
    padding: 11px 22px;
    border: none;
    font-size: 16px;
    border-radius: 4px;
  }

  &__add-item {
    height: 37px;
    border-radius: 2px;
    color: white;
    font-size: 12px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line {
    height: 5px;
    background: #dddddd;

    &--xs {
      width: 32px;
    }

    &--s {
      width: 42px;
    }

    &--m {
      width: 52px;
    }

    &--l {
      width: 92px;
    }

    &--xl {
      width: 122px;
    }

    &--dark {
      background: #acacac;
    }
  }

  .in-line {
    display: flex;
    align-items: center;
  }

  .in-line-between {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.half {
      width: 47%;
      display: inline-block;
      overflow: hidden;

      &:nth-child(even) {
        margin-right: 0.8125rem;
      }
    }
  }

  .dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  .square {
    width: 35px;
    height: 35px;
    background: rgb(221, 221, 221);

    &--full {
      width: 100%;
      height: 100px;
      border-radius: 2px;
    }
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-b-3 {
    margin-bottom: 3px;
  }

  .m-b-5 {
    margin-bottom: 5px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .m-r-4 {
    margin-right: 4px;
  }

  .m-l-10 {
    margin-left: 10px;
  }
}
</style>
