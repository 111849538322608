export const isLocalStorageAvailable = () => {
  var test = "test";

  try {
    window.localStorage.setItem(test, test);
    window.localStorage.removeItem(test);

    return true;
  } catch (e) {
    return false;
  }
};

const getLocalStorage = () => ({
  getItem: () => {},
  setItem: () => {},
});

export const getLocalStorageOrMock = () => {
  return isLocalStorageAvailable() ? window.localStorage : getLocalStorage();
};
