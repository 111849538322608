export function validateImage(
  imageFile: File,
  validatorConfig: IValidateImageConfig
): Promise<ValidateImageEnum> {
  return new Promise((res) => {
    if (!(imageFile.type === "image/jpeg" || imageFile.type === "image/png")) {
      res(ValidateImageEnum.TYPE);
      return;
    }

    if (
      validatorConfig.maxFileSizeMB &&
      imageFile.size > validatorConfig.maxFileSizeMB * 1000000
    ) {
      res(ValidateImageEnum.MAX_FILE_SIZE);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event): void => {
      const img = new Image();
      img.addEventListener("load", (): void => {
        if (validatorConfig.minWidth && img.width < validatorConfig.minWidth) {
          res(ValidateImageEnum.MIN_WIDTH);
          return;
        }

        if (
          validatorConfig.minHeight &&
          img.height < validatorConfig.minHeight
        ) {
          res(ValidateImageEnum.MIN_HEIGHT);
          return;
        }

        if (validatorConfig.maxWidth && img.width > validatorConfig.maxWidth) {
          res(ValidateImageEnum.MAX_WIDTH);
          return;
        }

        if (
          validatorConfig.maxHeight &&
          img.height > validatorConfig.maxHeight
        ) {
          res(ValidateImageEnum.MAX_HEIGHT);
          return;
        }

        return res(ValidateImageEnum.VALID);
      });

      // @ts-ignore
      img.src = event.target.result;
    };
    reader.readAsDataURL(imageFile);
  });
}

export enum ValidateImageEnum {
  MAX_FILE_SIZE = "MAX_FILE_SIZE",
  TYPE = "TYPE",
  MIN_WIDTH = "MIN_WIDTH",
  MIN_HEIGHT = "MIN_HEIGHT",
  MAX_WIDTH = "MAX_WIDTH",
  MAX_HEIGHT = "MAX_HEIGHT",
  VALID = "VALID",
}

export interface IValidateImageConfig {
  maxFileSizeMB?: number;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
}
