import Enumeration from "./enumeration";

/**
 * List of shapes
 * @returns {Enumeration<statuses>}
 */
export const widgetShape = new Enumeration({
  ROUNDED_EDGES: "ROUNDED_EDGES",
  SQUARE: "SQUARE",
  CIRCLE: "CIRCLE",
});

/**
 * List of positions
 * @returns {Enumeration<statuses>}
 */
export const widgetPosition = new Enumeration({
  BOTTOM_RIGHT: "BOTTOM_RIGHT",
  TOP_RIGHT: "TOP_RIGHT",
});

/**
 * List of styles
 * @returns {Enumeration<statuses>}
 */
export const widgetStyle = new Enumeration({
  BUBBLE_ICON: "BUBBLE_ICON",
  BUTTON_ONLY: "BUTTON_ONLY",
});
