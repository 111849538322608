<template>
  <div class="p_top_50 p_bottom_50 mx-auto">
    <div class="title d-flex align-items-center m_bottom_24">
      <Icon icon="facebook" :size="40" />
      <h4 class="m-0 p_left_16">
        {{ $t("c.channels.facebook_connect.post.title") }}
      </h4>
    </div>
    <div class="description m_bottom_24">
      <p class="m_bottom_33">
        {{ $t("c.channels.facebook_connect.post.p_1") }}
      </p>
      <!-- eslint-disable -->
      <p
        class="m-0"
        v-html="$t('c.channels.facebook_connect.post.p_2', { url: orderUrl })"
      ></p>
      <!-- eslint-enable -->
    </div>
    <LinkBox :link="orderUrl" class="m_bottom_32" />
    <div
      class="image-wrapper d-flex justify-content-center align-items-center py-5 m_bottom_80"
    >
      <img
        src="@/modules/sales-channels/assets/img/facebook-instruction.png"
        alt="Facebook Instruction"
      />
    </div>
    <HelpFeedback
      :business-id="currentBusinessId"
      :business-name="businessName"
      :channel="channels.FACEBOOK"
    />
    <hr />
  </div>
</template>

<script>
import { Icon } from "@ds";
import { mapGetters } from "vuex";
import { find, propOr } from "ramda";
import LinkBox from "@/modules/sales-channels/ui/components/LinkBox.vue";
import HelpFeedback from "@/modules/sales-channels/ui/components/HelpFeedback.vue";
import GET_SALES_CHANNEL from "@/modules/sales-channels/graphql/GetSalesChannel.gql";
import { channels } from "@/utils/enums/channels";

export default {
  name: "FacebookPost",
  components: {
    Icon,
    LinkBox,
    HelpFeedback,
  },
  data: () => ({
    channels,
  }),
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    orderUrl() {
      const business = find(
        (obj) => obj.id === this.currentBusinessId,
        this.businesses
      );
      return `${process.env.VUE_APP_WEBVIEW_URL}/f/${propOr(
        "",
        "url",
        business
      )}`;
    },
    currentBusinessId() {
      return this.$route.params.businessId;
    },
    businessName() {
      const business = find(
        (obj) => obj.id === this.currentBusinessId,
        this.businesses
      );
      return propOr("", "name", business);
    },
  },
  apollo: {
    salesChannel: {
      query: GET_SALES_CHANNEL,
      variables() {
        return {
          businessId: this.currentBusinessId,
          salesChannelEnum: channels.FACEBOOK,
        };
      },
      update(response) {
        const channel = response.business.salesChannels[0];
        return {
          username: channel.username,
          verificationStatus: channel.state,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  background: rgba($color: #dee2e6, $alpha: 0.5);
}
</style>
