<template>
  <div class="p-4">
    <h5 class="mb-4">{{ $t("c.channels.prerequisite.title_1") }}</h5>
    <p>{{ $t("c.channels.prerequisite.paragraph_1") }}</p>
    <p>{{ $t("c.channels.prerequisite.paragraph_2") }}</p>
    <strong>{{ $t("c.channels.prerequisite.title_2") }}</strong>
    <i18n path="c.channels.prerequisite.paragraph_3" tag="p">
      <router-link
        :to="{
          name: 'menus.index',
          params: { businessId: businessId },
        }"
        target="_blank"
        rel="noopener noreferrer"
        class="d-inline-block text-underline"
      >
        {{ $t("label.here") }}
      </router-link>
    </i18n>
    <strong>{{ $t("c.channels.prerequisite.title_3") }}</strong>
    <i18n path="c.channels.prerequisite.paragraph_4" tag="p">
      <router-link
        :to="{
          name: 'outlets.index',
          params: { businessId: businessId },
        }"
        target="_blank"
        rel="noopener noreferrer"
        class="d-inline-block text-underline"
      >
        {{ $t("label.here") }}
      </router-link>
    </i18n>
    <p>{{ $t("c.channels.prerequisite.paragraph_5") }}</p>
    <div class="row align-items-center">
      <div class="col-sm-auto mt-3">
        <Button @click.prevent="agree">
          {{ $t("c.channels.prerequisite.cta") }}
        </Button>
      </div>
      <div class="col-sm mt-3">
        <Checkbox v-model="doNotDisplayAgain" class="m-0">
          {{ $t("c.channels.prerequisite.cta_checkbox") }}
        </Checkbox>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BUTTON_CLICKED } from "@/plugins/analytics/events";
import { userChoices } from "@/composable/userChoices";
import { Button, Checkbox, Anchor } from "@ds";

export default {
  name: "PrerequisiteModal",
  components: {
    Button,
    Checkbox
  },
  props: {
    businessId: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
  },
  data: () => ({
    doNotDisplayAgain: false
  }),
  methods: {
    agree() {
      if (this.doNotDisplayAgain) {
        userChoices(this.businessId).acknowledgeSalesChannelsPrerequisites();

        this.$analytics.trackEvent(BUTTON_CLICKED, {
          label: "Ok, I understand"
        });
      }
      
      this.$emit("close");
      this.$router.push({ name: this.routeName });
    }
  }
};
</script>
