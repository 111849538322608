<template>
  <span
    class="status btn text-capitalize"
    :class="statusClass"
    :data-test="status"
  >
    {{ text }}
    <span v-if="isComplete" class="status__icon">
      <CheckIcon class="w-8 fill_white" />
    </span>
  </span>
</template>

<script>
import { statuses } from "@/utils/enums/channels";
import { replace, toLower } from "ramda";
import CheckIcon from "@/assets/svg/icons/check.svg";

export default {
  name: "StatusBadge",
  components: {
    CheckIcon,
  },
  props: {
    status: {
      type: String,
      required: true,
      validator: (val) => statuses.includes(val),
    },
  },
  data: () => ({
    statuses,
  }),
  computed: {
    statusClass() {
      return replace(/ /g, "-", toLower(this.status));
    },
    isComplete() {
      return this.status === statuses.COMPLETE;
    },
    text() {
      switch (this.status) {
        case statuses.INACTIVE:
          return this.$t("c.channels.sales_channels_listing.connect");
        case statuses.PENDING:
          return this.$t("c.channels.common.pending");
        case statuses.COMPLETE:
          return this.$t("c.channels.sales_channels_listing.connected");
        default:
          return this.$t(
            "c.channels.sales_channels_listing.contact_customer_support"
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  font-size: 13px;
  padding: 7px 12px;
  border-radius: 4px;

  &__icon {
    display: inline-flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #70cc49;
  }
}

.inactive {
  color: #00a8f8;
  background: rgba($color: #00a8f8, $alpha: 0.1);
}

.complete {
  color: #70cc49;
  background: #fff;
}

.contact-customer-support {
  color: #a33bf4;
  background: rgba($color: #a33bf4, $alpha: 0.1);
}

.pending {
  color: #f4c007;
  background: #fff;
}
</style>
