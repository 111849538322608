<template>
  <div class="content-body">
    <h5 class="mb-3">
      {{ $t("c.channels.sales_channels_listing.title") }}
    </h5>
    <p class="text-muted mb-4">
      {{ $t("c.channels.sales_channels_listing.content") }}
    </p>
    <LoadingZone :loading="isLoading">
      <div class="row align-items-stretch">
        <div
          v-for="channel in salesChannels"
          :key="channel.id"
          class="col-md-6 mb-4"
        >
          <ChannelCard
            :route-name="channel.routeName"
            :icon="channel.icon"
            :business-id="$attrs.businessId"
            :title="channel.title"
            :status="channel.state"
            :helper-website="channel.helperWebsite"
            :description="channel.description"
          />
        </div>
      </div>
    </LoadingZone>
  </div>
</template>

<script>
import GET_SALES_CHANNELS_LIST from "@/modules/sales-channels/graphql/GetSalesChannelsList.gql";
import ChannelCard from "@/modules/sales-channels/ui/components/ChannelCard.vue";
import { LoadingZone } from "@/design-system";
import { toLower } from "ramda";
import { EventCategoryEnum, EventEnum } from "@/v2/enum";
import { useHelpers } from "@/v2/composable/use-helpers";
import { mapGetters } from "vuex";

export default {
  name: "SalesChannelsList",
  components: {
    ChannelCard,
    LoadingZone,
  },
  apollo: {
    salesChannels: {
      query: GET_SALES_CHANNELS_LIST,
      variables() {
        return {
          businessId: this.$attrs.businessId,
        };
      },
      update(response) {
        return response.business.salesChannels.map((salesChannel) => {
          const channelName = toLower(salesChannel.channel);
          const isWebsite = channelName === "website";

          return {
            routeName: `sales_channels.${channelName}.index`,
            icon: isWebsite ? "chatfood-icon" : channelName,
            state: salesChannel.state,
            title: this.$t(`c.channels.common.connections.${channelName}`),
            helperWebsite: isWebsite,
            description: this.$t(
              `c.channels.sales_channels_listing.${channelName}_description`
            ),
          };
        });
      },
    },
  },
  data: () => ({ EventEnum }),
  computed: {
    ...mapGetters({
      permissions: "auth/getPermissions",
    }),
    isLoading() {
      return this.$apollo.queries.salesChannels.loading;
    },
    business() {
      const { id, name } =
        useHelpers().getCurrentBusiness(this.$attrs.businessId) || {};

      return { id, name };
    },
  },
};
</script>

<style lang="scss" scoped>
.content-body {
  padding: 5vmax;

  .fbe-integration {
    margin-bottom: var(--space-14);
  }
}
</style>
