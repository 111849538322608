<template>
  <div class="verification">
    <Button
      v-if="isInactive"
      class="verification__btn"
      :class="`verification__btn--${status.toLowerCase()}`"
      :is-loading="isLoading"
      @click="onClick"
    >
      {{ buttonText }}
    </Button>
    <Button
      v-else
      class="verification__btn"
      :class="`verification__btn--${status.toLowerCase()}`"
    >
      {{ buttonText }}
      <Icon v-if="isComplete" icon="check-circle" class="ml-2" />
    </Button>
  </div>
</template>

<script>
import { channels, statuses } from "@/utils/enums/channels";
import { Button, Icon } from "@/design-system";
import { toLower } from "ramda";

export default {
  name: "VerificationButton",
  components: {
    Button,
    Icon,
  },
  props: {
    channel: {
      type: String,
      required: true,
      validator: (val) => channels.includes(val),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      required: true,
      validator: (val) => statuses.has(val),
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isPending() {
      return this.status === statuses.PENDING;
    },
    isComplete() {
      return this.status === statuses.COMPLETE;
    },
    isInactive() {
      return this.status === statuses.INACTIVE;
    },
    buttonText() {
      const channelName = this.$t(
        `c.channels.common.connections.${toLower(this.channel)}`
      );

      switch (true) {
        case this.isInactive:
          return this.$t("c.channels.common.verify_connection");
        case this.isPending:
          return this.$t("c.channels.common.verification_pending");
        case this.isComplete:
          return this.$t("c.channels.common.channel_connected", {
            channel: channelName,
          });
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.verification__btn {
  &--pending,
  &--pending:hover,
  &--pending:focus {
    color: #f4c007;
    cursor: default;
    background: rgba($color: #f4c007, $alpha: 0.1);
  }

  &--complete,
  &--complete:hover,
  &--complete:focus {
    cursor: default;
    background: #70cc49;
  }
}
</style>
