<template>
  <div class="link-box d-flex flex-row">
    <span
      class="link-box--text cusor-pointer"
      data-test="sales-channel-link-box-link"
    >
      <span>{{ link }}</span>
    </span>
    <span
      v-tooltip="{
        autoHide: false,
        trigger: 'click hover',
        content: `${$t('label.copy')}`,
      }"
      data-test="copy"
      class="link-box--button ml-auto cursor-pointer"
      @click.prevent="copyToClipboard(link)"
    >
      <CopySVG class="w-20" />
      <input ref="link" type="hidden" />
      {{ isLink ? $t("label.copy_link") : $t("label.copy_id") }}
    </span>
  </div>
</template>

<script>
import CopySVG from "@/assets/svg/icons/copy.svg";
import { Toast } from "@ds";

export default {
  name: "LinkBox",
  components: {
    CopySVG,
  },
  props: {
    link: {
      type: String,
      required: true,
    },
    isLink: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    copyToClipboard(link) {
      if (!window.navigator.clipboard) {
        this.fallbackCopyToClipboard(link);
        return;
      }

      try {
        window.navigator.clipboard.writeText(link);
        new Toast().create({
          type: "success",
          title: this.$t("message.link_copied"),
        });
      } catch (e) {
        new Toast().create({
          type: "error",
          title: this.$t("message.generic_failure"),
        });
      }
    },
    fallbackCopyToClipboard(link) {
      try {
        const linkToCopy = this.$refs.link;
        linkToCopy.setAttribute("type", "text");
        linkToCopy.value = link;
        linkToCopy.select();

        document.execCommand("copy");
        new Toast().create({
          type: "success",
          title: this.$t("message.link_copied"),
        });

        linkToCopy.setAttribute("type", "hidden");
        window.getSelection().removeAllRanges();
      } catch (e) {
        new Toast().create({
          type: "error",
          title: this.$t("message.generic_failure"),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link-box {
  background: rgba(0, 168, 248, 0.1);
  border: 1px dashed #00a8f8;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 20px 14px;
  font-size: 1rem;

  * {
    color: #00a8f8;
    fill: #00a8f8;

    &:hover {
      color: rgba($color: #00a8f8, $alpha: 0.8);
      fill: rgba($color: #00a8f8, $alpha: 0.8);
    }
  }
}
</style>
