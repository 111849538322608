<template>
  <LoadingZone
    :loading="$apollo.queries.widgetSettings.loading"
    :dom-visibility="false"
  >
    <div class="design">
      <div class="design__customization">
        <h1 class="design__title">
          {{ $t("c.channels.widget.design_title") }}
        </h1>
        <p class="design__description">
          {{ $t("c.channels.widget.design_description") }}
        </p>
        <form data-test="form" @submit.prevent="submitForm">
          <OrgFormImageDragSelector
            :label="$t('c.channels.widget.cover_image')"
            :loading="coverLoading"
            :on-remove="() => (businessSettings.coverImage = '')"
            :on-select="onSelectCover"
            :preview-image-url="imageURLBuilder(businessSettings.coverImage)"
            :instruction-text="$t('message.drag_drop_cover_photo')"
            :caption="$t('c.channels.widget.helper_text_for_cover_photo')"
          />
          <OrgImageCropper
            v-if="coverImageUpload"
            :img-src="coverImageUpload"
            :ratio="9 / 5"
            :min-height="450"
            :min-width="250"
            :on-crop="
              (blob) => {
                uploadCover(blob);
                coverImageUpload = '';
              }
            "
            :on-cancel="() => (coverImageUpload = '')"
          />
          <div class="design__colors mb-4">
            <div class="design__colors-wrapper">
              <FormControl
                class="design__button-color"
                :label="$t('c.channels.widget.button_color_label')"
                :error="errorMessageFor('widgetSettings.baseColor')"
              >
                <InputColor
                  v-model="widgetSettings.baseColor"
                  :default-hex-color="widgetSettings.baseColor"
                  :has-errors="hasErrorFor('widgetSettings.baseColor')"
                />
              </FormControl>
              <FormControl
                :label="$t('c.channels.widget.button_text_color_label')"
                :error="errorMessageFor('widgetSettings.textColor')"
              >
                <InputColor
                  v-model="widgetSettings.textColor"
                  :default-hex-color="widgetSettings.textColor"
                  :has-errors="hasErrorFor('widgetSettings.textColor')"
                />
              </FormControl>
            </div>
            <div v-if="contrastRatio < 3" class="design__contrast-ratio">
              {{ $t("c.channels.widget.contrast_ratio") }}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://docs.chatfood.io/en/articles/4311872-color-contrast-issues"
              >
                {{ $t("c.channels.widget.contrast_ratio_link") }}
              </a>
            </div>
          </div>
          <FormControl
            class="design__shape-form-controll mb-4"
            :label="$t('c.channels.widget.default_menu_layout')"
          >
            <InlineSelectorGroup class="design__layout-group">
              <InlineSelector
                v-for="layout in layouts"
                :key="layout"
                v-model="businessSettings.menuDisplayLayout"
                :value="layout"
                :name="layout"
              >
                <ListIcon v-if="layout === MenuDisplayLayoutEnum.LIST" />
                <GridIcon v-else />
              </InlineSelector>
            </InlineSelectorGroup>
          </FormControl>
          <p class="design__secondary-title">
            {{ $t("c.channels.widget.design_secondary_title") }}
            <AtomHelpTooltip
              :content="$t('c.channels.widget.design_title_info')"
            />
          </p>
          <FormControl
            v-if="isNewVersion"
            class="design__shape-form-controll mb-4"
            :label="$t('c.channels.widget.button_shape')"
          >
            <InlineSelectorGroup class="design__shape-group">
              <InlineSelector
                v-for="shape in shapes"
                :key="shape"
                v-model="widgetSettings.widgetShape"
                :class="[`design__${shape.replace('_', '-').toLowerCase()}`]"
                :value="shape"
              >
              </InlineSelector>
            </InlineSelectorGroup>
          </FormControl>
          <FormControl
            v-if="isNewVersion"
            class="design__button-text mb-4"
            :label="$t('c.channels.widget.button_text')"
            :hint="$t('c.channels.widget.button_text_hint')"
            :error="errorMessageFor('widgetSettings.buttonText')"
          >
            <Input
              v-model="widgetSettings.buttonText"
              type="text"
              :maxlength="10"
              :has-errors="hasErrorFor('widgetSettings.buttonText')"
            />
          </FormControl>
          <FormControl
            v-if="isNewVersion"
            class="design__shape-form-controll mb-4"
            :label="$t('c.channels.widget.position')"
            :tooltip="$t('c.channels.widget.position_tooltip')"
          >
            <InlineSelectorGroup class="design__position-group">
              <InlineSelector
                v-for="position in positions"
                :key="position"
                v-model="widgetSettings.widgetPosition"
                :class="[`design__${position.replace('_', '-').toLowerCase()}`]"
                :value="position"
              >
                <img :src="positionImagePath(position)" />
              </InlineSelector>
            </InlineSelectorGroup>
          </FormControl>
          <FormControl v-if="isNewVersion" class="mb-4 design__bubble">
            <Radio
              v-for="style in styles"
              :key="style"
              v-model="widgetSettings.widgetStyle"
              name="type"
              :value="style"
            >
              {{ $t(`c.channels.widget.style_${style.toLowerCase()}`) }}
            </Radio>
          </FormControl>
          <template v-if="showBubble">
            <FormControl
              class="mb-4"
              :label="$t('c.channels.widget.headline_label')"
              :hint="$t('c.channels.widget.headline_hint')"
              :error="errorMessageFor('widgetSettings.headline')"
            >
              <Input
                v-model="widgetSettings.headline"
                type="text"
                :maxlength="20"
                :has-errors="hasErrorFor('widgetSettings.headline')"
              />
            </FormControl>
            <FormControl
              class="mb-4"
              :label="$t('c.channels.widget.design_message_label')"
              :hint="$t('c.channels.widget.message_hint')"
              :error="errorMessageFor('widgetSettings.message')"
            >
              <Input
                v-model="widgetSettings.message"
                type="text"
                :maxlength="65"
                :has-errors="hasErrorFor('widgetSettings.message')"
              />
            </FormControl>
            <FormControl
              class="mb-4"
              :label="$t('c.channels.widget.icon_image')"
              :error="errorMessageFor('file')"
              :tooltip="$t('c.channels.widget.tooltip_text_for_icon')"
            >
              <UploadIcon
                :url="widgetSettings.icon"
                :icon-size="48"
                icon-name="chatfood-logo"
                @removeImage="widgetSettings.icon = ''"
                @uploadImage="onSelectIcon"
              />
              <OrgImageCropper
                v-if="iconImageUpload"
                :img-src="iconImageUpload"
                :ratio="1"
                :min-height="200"
                :min-width="200"
                :on-crop="
                  (blob) => {
                    uploadImage(blob);
                    iconImageUpload = '';
                  }
                "
                :on-cancel="() => (iconImageUpload = '')"
              />
            </FormControl>
          </template>
          <Button type="submit" :is-loading="formLoading">
            {{ $t("c.channels.widget.design_save_button") }}
          </Button>
        </form>
      </div>
      <WidgetPreview
        :button-text="
          widgetSettings.buttonText ||
          $t('c.channels.widget.preview_action_button')
        "
        :primary-color="widgetSettings.baseColor"
        :secondary-color="widgetSettings.textColor"
        :primary-light-color="primaryLightColor"
        :headline="widgetSettings.headline"
        :message="widgetSettings.message"
        :icon-url="widgetSettings.icon"
        :cover-url="businessSettings.coverImage"
        shape="ROUNDED_EDGES"
        general-style="BUBBLE_ICON"
        :display-layout="businessSettings.menuDisplayLayout"
      />
    </div>
  </LoadingZone>
</template>

<script>
import UPLOAD_WIDGET_ICON from "@/modules/branding/graphql/UploadWidgetIcon.gql";
import UPLOAD_BUSINESS_COVER_IMAGE from "@/modules/branding/graphql/UploadBusinessCoverImage.gql";
import GET_WIDGET_SETTINGS from "@/modules/branding/graphql/GetWidgetSettings.gql";
import GET_COVER_IMAGE from "@/modules/branding/graphql/GetCoverImage.gql";
import UPDATE_WIDGET_DISPLAY_SETTINGS from "@/modules/branding/graphql/UpdateWidgetDisplaySettings.gql";
import WidgetPreview from "@/modules/branding/ui/components/WidgetPreview.vue";
import { imageURLBuilder } from "@/v2/util/image-url-builder";
import { OrgImageCropper } from "@/v2/design-system/org/image-cropper";
import {
  FormControl,
  Input,
  Button,
  InputColor,
  UploadIcon,
  Toast,
  LoadingZone,
  InlineSelectorGroup,
  InlineSelector,
  Radio,
} from "@ds";
import { propOr, pathOr, isEmpty, has, omit } from "ramda";
import { widgetShape, widgetPosition, widgetStyle } from "@/utils/enums/widget";
import { MenuDisplayLayoutEnum } from "@/v2/enum/menu-display-layout-enum";
import { AtomHelpTooltip, OrgFormImageDragSelector } from "@/v2/design-system";
import { validateImage, ValidateImageEnum } from "@/v2/util/validate-image";
import { imageValidationErrorToast } from "@/v2/util/image-validation-error-toast";
import { mapGetters } from "vuex";
import GridIcon from "@/modules/branding/assets/img/grid.svg";
import ListIcon from "@/modules/branding/assets/img/list.svg";

export default {
  components: {
    WidgetPreview,
    FormControl,
    AtomHelpTooltip,
    Input,
    Button,
    InputColor,
    UploadIcon,
    LoadingZone,
    InlineSelectorGroup,
    InlineSelector,
    Radio,
    OrgImageCropper,
    OrgFormImageDragSelector,
    GridIcon,
    ListIcon,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      imageURLBuilder,
    };
  },
  data: () => ({
    imageLoading: false,
    coverLoading: false,
    formLoading: false,
    isNewVersion: false,
    coverImageUpload: "",
    iconImageUpload: "",
    widgetSettings: {},
    businessSettings: {},
    errors: {},
    shapes: widgetShape.values(),
    positions: widgetPosition.values(),
    styles: widgetStyle.values(),
    layouts: MenuDisplayLayoutEnum,
    MenuDisplayLayoutEnum,
  }),
  apollo: {
    widgetSettings: {
      query: GET_WIDGET_SETTINGS,
      variables() {
        return {
          businessId: this.businessId,
        };
      },
      update(response) {
        const settings = response.business.widgetSettings;
        if (!settings.buttonText && this.isNewVersion) {
          settings.buttonText = this.$t(
            "c.channels.widget.preview_action_button"
          );
        }
        return omit(["__typename"], settings);
      },
    },
    businessSettings: {
      query: GET_COVER_IMAGE,
      fetchPolicy: "no-cache",
      variables() {
        return {
          businessId: this.businessId,
        };
      },
      update: (response) => response.business,
    },
  },
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    business() {
      return Object.values(this.businesses).find(
        (obj) => obj.id === this.businessId
      );
    },
    primaryLightColor() {
      if (!this.widgetSettings.baseColor) return "";
      return this.blendColors(this.widgetSettings.baseColor, "#F5F5F5", 0.92);
    },
    primaryDarkColor() {
      if (!this.widgetSettings.baseColor) return "";
      return this.blendColors(this.widgetSettings.baseColor, "#000000", 0.22);
    },
    showBubble() {
      if (!this.widgetSettings.widgetStyle) return true;
      return this.widgetSettings.widgetStyle == widgetStyle.BUBBLE_ICON;
    },
    contrastRatio() {
      if (!this.widgetSettings.baseColor) return false;
      const rgb1 = this.hexToRgb(this.widgetSettings.baseColor);
      const rgb2 = this.hexToRgb(this.widgetSettings.textColor);
      const lum1 = this.luminanace(rgb1["r"], rgb1["g"], rgb1["b"]);
      const lum2 = this.luminanace(rgb2["r"], rgb2["g"], rgb2["b"]);
      const brightest = Math.max(lum1, lum2);
      const darkest = Math.min(lum1, lum2);
      return (brightest + 0.05) / (darkest + 0.05);
    },
    colorSet() {
      return {
        colorPrimary: this.widgetSettings.baseColor,
        colorOnPrimary: this.widgetSettings.textColor,
        colorPrimaryHover: this.primaryDarkColor,
        colorOnPrimaryHover: this.widgetSettings.textColor,
        colorPrimaryLight: this.primaryLightColor,
        colorPrimaryDark: this.primaryDarkColor,
      };
    },
  },
  methods: {
    getImageDataUrl(imageFile) {
      return URL.createObjectURL(imageFile);
    },
    blendColors(colorA, colorB, amount) {
      const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16));
      const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16));
      const r = Math.round(rA + (rB - rA) * amount)
        .toString(16)
        .padStart(2, "0");
      const g = Math.round(gA + (gB - gA) * amount)
        .toString(16)
        .padStart(2, "0");
      const b = Math.round(bA + (bB - bA) * amount)
        .toString(16)
        .padStart(2, "0");
      return "#" + r + g + b;
    },
    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
    luminanace(r, g, b) {
      const a = [r, g, b].map((v) => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
      });
      return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    },
    errorMessageFor(item) {
      return pathOr("", [item, 0], this.errors);
    },
    hasErrorFor(item) {
      return !isEmpty(propOr("", item, this.errors));
    },
    setErrors(error) {
      const errors = error.graphQLErrors ? error.graphQLErrors : error;
      this.errors = pathOr(null, ["0", "extensions", "validation"], errors);
    },
    async onSelectCover(imageFile) {
      const imageValidationConfig = {
        maxFileSizeMB: 1,
        minWidth: 450,
        minHeight: 250,
      };

      const imageValidation = await validateImage(
        imageFile,
        imageValidationConfig
      );

      imageValidationErrorToast(imageValidation, imageValidationConfig);

      if (imageValidation === ValidateImageEnum.VALID) {
        this.coverImageUpload = this.getImageDataUrl(imageFile);
      }
    },
    async onSelectIcon(imageFile) {
      const imageValidationConfig = {
        maxFileSizeMB: 1,
        minWidth: 200,
        minHeight: 200,
      };

      const imageValidation = await validateImage(
        imageFile,
        imageValidationConfig
      );

      imageValidationErrorToast(imageValidation, imageValidationConfig);

      if (imageValidation === ValidateImageEnum.VALID) {
        this.iconImageUpload = this.getImageDataUrl(imageFile);
      }
    },
    async uploadCover(blob) {
      blob.lastModifiedDate = new Date();
      this.coverLoading = true;

      try {
        const image = await this.$apollo.mutate({
          mutation: UPLOAD_BUSINESS_COVER_IMAGE,
          variables: {
            file: new File([blob], "cover-upload.jpeg", {
              type: "image/jpeg",
              lastModified: new Date(),
            }),
            businessId: this.businessId,
          },
          context: {
            hasUpload: true,
          },
        });

        if (has("errors", image)) {
          throw image.errors;
        }

        this.businessSettings.coverImage = image.data.uploadBusinessCoverImage;
      } catch (error) {
        const coverError = pathOr(
          null,
          ["0", "extensions", "validation", "businessCoverImage"],
          error
        );

        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: coverError || this.$t("c.channels.widget.error_upload"),
        });
      } finally {
        this.$modal.hide("cover");
        this.coverLoading = false;
      }
    },
    async uploadImage(blob) {
      blob.lastModifiedDate = new Date();
      this.imageLoading = true;

      try {
        const image = await this.$apollo.mutate({
          mutation: UPLOAD_WIDGET_ICON,
          variables: {
            file: new File([blob], "widget-icon.jpeg", {
              type: "image/jpeg",
              lastModified: new Date(),
            }),
            businessId: this.businessId,
          },
          context: {
            hasUpload: true,
          },
        });

        if (has("errors", image)) {
          throw image.errors;
        }

        this.widgetSettings.icon = image.data.uploadWebsiteWidgetIcon;
      } catch (error) {
        const imageError = pathOr(
          null,
          ["0", "extensions", "validation", "file"],
          error
        );

        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: imageError || this.$t("c.channels.widget.error_upload"),
        });
      } finally {
        this.$modal.hide("image-crop");
        this.imageLoading = false;
      }
    },
    async submitForm() {
      this.formLoading = true;
      this.errors = {};

      try {
        const image = await this.$apollo.mutate({
          mutation: UPDATE_WIDGET_DISPLAY_SETTINGS,
          variables: {
            widgetSettings: this.widgetSettings,
            businessId: this.businessId,
            colorSet: this.colorSet,
            coverImageUrl: this.businessSettings.coverImage,
            menuDisplayLayout: this.businessSettings.menuDisplayLayout,
          },
        });

        if (has("errors", image)) {
          throw image.errors;
        }

        this.$analytics.trackEvent("Customized branding", {
          business_id: this.businessId,
          business_name: this.business.name,
        });

        if (!this.isOnboardingStepCompleted && this.isOnboarding) {
          this.$router.push({
            name: "onboarding",
            params: {
              businessId: this.businessId,
            },
          });
        }

        new Toast().create({
          type: "success",
          title: this.$t("label.success"),
          text: this.$t("c.channels.widget.success_message"),
        });
      } catch (error) {
        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("c.channels.widget.error_message"),
        });

        this.setErrors(error);
      } finally {
        this.formLoading = false;
      }
    },
    positionImagePath(name) {
      const position = name == widgetPosition.BOTTOM_RIGHT ? "bottom" : "top",
        type = this.showBubble ? "" : "button-";
      return require("@/modules/branding/assets/img/widget-" +
        type +
        position +
        ".png");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .design {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: baseline;
  }

  &__customization {
    max-width: 600px;
  }

  &__title {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 5px;
  }

  &__description {
    font-size: 14px;
    color: rgb(132, 160, 178);
    margin-bottom: 30px;
  }

  &__colors-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .form-field {
      width: calc(50% - 10px);
    }
  }

  &__contrast-ratio {
    color: rgb(132, 160, 178);
    font-size: 13px;
    margin-top: 7px;

    a {
      color: rgb(132, 160, 178);
      font-size: 13px;
      text-decoration: underline;
    }
  }

  &__button-color {
    margin-right: 30px;
  }

  &__shape-form-controll {
    .form-field__label-wrapper {
      margin-bottom: 12px;
      height: 16px;
    }
  }

  &__shape-group {
    padding: 0;
    border: none;

    .selector__value {
      width: 48px;
      height: 48px;
      background: #f9fbfc;
      border: 1px solid #dee2e6;
      margin-right: 20px;
    }

    .selector__input:checked ~ .selector__value {
      background: #f9fbfc;
      border: 2px solid #00a8f8;
    }
  }

  &__layout-group {
    padding: 0;
    border: none;

    .selector__value {
      width: 3.75rem;
      height: 3.75rem;
      padding: 0;
      margin-right: 1.25rem;
    }

    .selector__input:checked ~ .selector__value {
      background: none;
      border: 0;
      svg g {
        stroke: #00a8f8;
        stroke-width: 2;
      }
    }
  }

  &__rounded-edges {
    .selector__value {
      border-radius: 10px;
    }
  }

  &__circle {
    .selector__value {
      border-radius: 100px;
    }
  }

  &__position-group {
    padding: 0;
    border: none;
    justify-content: space-between;
    width: 100%;

    .selector__value {
      height: auto;
      padding: 0;
      background: none;
      border: none;
    }

    .selector__input:checked ~ .selector__value {
      background: none;
      border: none;

      img {
        border: 2px solid #00a8f8;
      }
    }

    img {
      width: 150px;
      height: 96px;
      border-radius: 5px;
      border: 2px solid white;
    }
  }

  &__bubble {
    display: flex;

    .radio {
      margin-right: 25px;
    }
  }

  &__secondary-title {
    margin: 10px 0 20px;
    color: rgb(68, 68, 68);
    font-size: 17px;
    font-weight: 500;
  }

  .upload-image {
    background-size: contain;
  }
}
</style>
