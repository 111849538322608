<template>
  <aside class="sidebar-channels">
    <router-link
      class="sidebar-channels__link"
      :to="{
        name: 'sales_channels.index',
        params: { businessId: activeBusiness.id },
      }"
    >
      <strong>{{ $t("c.channels.sidebar.sales_channels") }}</strong>
    </router-link>
    <div v-for="(channel, index) in channels" :key="index">
      <router-link
        class="sidebar-channels__link"
        :to="{
          name: `sales_channels.${channel.toLowerCase()}.index`,
        }"
      >
        {{ $t(`c.channels.common.connections.${channel.toLowerCase()}`) }}
      </router-link>
      <div v-if="channel === channels.INSTAGRAM" class="pl-3">
        <router-link
          class="sidebar-channels__link"
          :to="{ name: 'sales_channels.instagram.food_button' }"
        >
          {{ $t("c.channels.instagram_connect.food_button.menu") }}
        </router-link>
        <router-link
          class="sidebar-channels__link"
          :to="{ name: 'sales_channels.instagram.stories_sticker' }"
        >
          {{ $t("c.channels.instagram_connect.stories_sticker.menu") }}
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
import { getActiveBusiness } from "@/utils/mixins";
import { channels } from "@/utils/enums/channels";

export default {
  name: "Sidebar",
  mixins: [getActiveBusiness],
  data: () => ({
    channels,
  }),
};
</script>

<style lang="scss" scoped>
.sidebar-channels {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 20px;

  &__link {
    display: block;
    color: #84a0b2;
    padding: 10px;

    &.router-link-exact-active {
      color: #444;
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus {
      color: #84a0b2;
      cursor: no-drop;
      text-decoration: none;
    }
  }

  @media (max-width: 676px) {
    display: flex;
  }
}
</style>
