import Enumeration from "./enumeration";

/**
 * List of sales channels
 *
 * @returns {Enumeration<channels>}
 */
export const channels = new Enumeration({
  WEBSITE: "WEBSITE",
  INSTAGRAM: "INSTAGRAM",
  WHATSAPP: "WHATSAPP",
  FACEBOOK: "FACEBOOK",
  MESSENGER: "MESSENGER",
  GOOGLEMYBUSINESS: "GOOGLE",
});

/**
 * List of channel status
 *
 * @returns {Enumeration<status>}
 */
export const statuses = new Enumeration({
  COMPLETE: "COMPLETE",
  PENDING: "PENDING",
  INACTIVE: "INACTIVE",
});
