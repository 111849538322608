import { choicesAttributes } from "@/utils/enums/choices-attributes";
import { getLocalStorageOrMock } from "./useLocalStorage";

export const userChoices = (businessId) => {
  const key = `cft:${businessId}`;

  const getObject = () => {
    const obj = getLocalStorageOrMock().getItem(key) ?? "{}";
    return JSON.parse(obj);
  };

  const hasAcknowledgedSalesChannelsPrerequisites = () => {
    return getObject()[choicesAttributes.ACKNOWLEDGED_SALES_CHANNEL] === true;
  };

  const acknowledgeSalesChannelsPrerequisites = () => {
    const obj = getObject();
    obj[choicesAttributes.ACKNOWLEDGED_SALES_CHANNEL] = true;
    getLocalStorageOrMock().setItem(key, JSON.stringify(obj));
  };

  return {
    hasAcknowledgedSalesChannelsPrerequisites,
    acknowledgeSalesChannelsPrerequisites,
  };
};
