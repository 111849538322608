<template>
  <div class="p_top_50 p_bottom_50 mx-auto">
    <div class="title d-flex align-items-center m_bottom_24">
      <Icon icon="facebook" :size="40" />
      <h4 class="m-0 p_left_16">
        {{ $t("c.channels.facebook_connect.profile.title") }}
      </h4>
    </div>
    <div class="description m_bottom_18">
      {{ $t("c.channels.facebook_connect.profile.description") }}
    </div>
    <h5 class="m_bottom_16">
      {{ $t("c.channels.common.step", { num: 1 }) }}
    </h5>
    <div class="m_bottom_32">
      {{ $t("c.channels.facebook_connect.profile.step1_description") }}
    </div>

    <LinkBox :link="orderUrl" class="m_bottom_46" />

    <h5 class="m_bottom_16">
      {{ $t("c.channels.common.step", { num: 2 }) }}
    </h5>
    <!-- eslint-disable -->
    <div
      class="m_bottom_32"
      v-html="$t('c.channels.facebook_connect.profile.step2_description')"
    ></div>
    <!-- eslint-enable -->
    <div
      class="d-flex justify-content-center align-items-center m_bottom_46"
      style="margin-right: -150px; margin-left: -150px"
    >
      <img
        src="@/modules/sales-channels/assets/img/facebook-profile-instruction-1.png"
        alt="Facebook Instruction"
      />
    </div>

    <h5 class="m_bottom_16">
      {{ $t("c.channels.common.step", { num: 3 }) }}
    </h5>
    <!-- eslint-disable -->
    <div
      class="m_bottom_32"
      v-html="$t('c.channels.facebook_connect.profile.step3_description')"
    ></div>
    <!-- eslint-enable -->

    <div
      class="d-flex justify-content-center align-items-center m_bottom_46"
      style="margin-right: -150px; margin-left: -150px"
    >
      <img
        src="@/modules/sales-channels/assets/img/facebook-profile-instruction-2.png"
        alt="Facebook Instruction"
      />
    </div>

    <h5 class="m_bottom_16">
      {{ $t("c.channels.common.step", { num: 4 }) }}
    </h5>
    <!-- eslint-disable -->
    <div
      class="m_bottom_32"
      v-html="$t('c.channels.facebook_connect.profile.step4_description')"
    ></div>
    <!-- eslint-enable -->
    <div
      class="d-flex justify-content-center align-items-center m_bottom_46"
      style="margin-right: -150px; margin-left: -150px"
    >
      <img
        src="@/modules/sales-channels/assets/img/facebook-profile-instruction-3.png"
        alt="Facebook Instruction"
      />
    </div>

    <h5 class="m_bottom_16">
      {{ $t("c.channels.common.step", { num: 5 }) }}
    </h5>
    <!-- eslint-disable -->
    <div
      class="m_bottom_32"
      v-html="$t('c.channels.facebook_connect.profile.step5_description')"
    ></div>
    <!-- eslint-enable -->
    <div
      class="d-flex justify-content-center align-items-center m_bottom_46"
      style="margin-right: -150px; margin-left: -150px"
    >
      <img
        src="@/modules/sales-channels/assets/img/facebook-profile-instruction-4.png"
        alt="Facebook Instruction"
      />
    </div>

    <h5 class="m_bottom_16">
      {{ $t("c.channels.common.step", { num: 6 }) }}
    </h5>
    <!-- eslint-disable -->
    <div
      class="m_bottom_32"
      v-html="$t('c.channels.facebook_connect.profile.step6_description1')"
    ></div>
    <!-- eslint-enable -->
    <div
      class="d-flex justify-content-center align-items-center m_bottom_32"
      style="margin-right: -150px; margin-left: -150px"
    >
      <img
        src="@/modules/sales-channels/assets/img/facebook-profile-instruction-5.png"
        alt="Facebook Instruction"
      />
    </div>
    <!-- eslint-disable -->
    <div
      class="m_bottom_32"
      v-html="$t('c.channels.facebook_connect.profile.step6_description2')"
    ></div>
    <!-- eslint-enable -->
    <div
      class="d-flex justify-content-center align-items-center m_bottom_80"
      style="margin-right: -150px; margin-left: -150px"
    >
      <img
        src="@/modules/sales-channels/assets/img/facebook-profile-instruction-6.png"
        alt="Facebook Instruction"
      />
    </div>

    <HelpFeedback
      :business-id="currentBusinessId"
      :business-name="businessName"
      :channel="channels.FACEBOOK"
      class="m_bottom_48"
    />

    <hr class="m_bottom_50" />

    <div class="d-flex justify-content-center">
      <VerificationButton
        :status="status"
        :channel="channels.FACEBOOK"
        :on-click="updateState"
      />
    </div>
  </div>
</template>

<script>
import { Icon, Toast } from "@ds";
import { mapGetters } from "vuex";
import { find, has, propOr } from "ramda";
import LinkBox from "@/modules/sales-channels/ui/components/LinkBox.vue";
import VerificationButton from "@/modules/sales-channels/ui/components/Button/VerificationButton.vue";
import GET_SALES_CHANNEL from "@/modules/sales-channels/graphql/GetSalesChannel.gql";
import HelpFeedback from "@/modules/sales-channels/ui/components/HelpFeedback.vue";
import VERIFY_SALES_CHANNEL from "@/modules/sales-channels/graphql/VerifySalesChannel.gql";
import { channels, statuses } from "@/utils/enums/channels";
import { SALES_CHANNEL_VERIFIED } from "@/plugins/analytics/events";

export default {
  name: "FacebookProfile",
  components: {
    Icon,
    LinkBox,
    VerificationButton,
    HelpFeedback,
  },
  data: () => ({
    salesChannel: {
      username: null,
      verificationStatus: null,
    },
    channels,
    statuses,
  }),
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    orderUrl() {
      return `${process.env.VUE_APP_WEBVIEW_URL}/f/${propOr(
        "",
        "url",
        this.currentBusiness
      )}`;
    },
    businessName() {
      return propOr("", "name", this.currentBusiness);
    },
    status() {
      return this.salesChannel.verificationStatus ?? statuses.INACTIVE;
    },
    currentBusinessId() {
      return this.$route.params.businessId;
    },
    currentBusiness() {
      return find((obj) => obj.id === this.currentBusinessId, this.businesses);
    },
  },
  methods: {
    async updateState() {
      try {
        const res = await this.$apollo.mutate({
          mutation: VERIFY_SALES_CHANNEL,
          variables: {
            businessId: this.currentBusinessId,
            salesChannelEnum: channels.FACEBOOK,
          },
        });

        if (has("errors", res)) throw res.errors;

        this.$analytics.trackEvent(SALES_CHANNEL_VERIFIED, {
          businessId: this.currentBusinessId,
          businessName: this.businessName,
          channel: channels.FACEBOOK,
        });

        new Toast().create({
          type: "success",
          tite: this.$t("label.success"),
          text: this.$t("c.channels.common.verification_success_message"),
        });

        this.$apollo.queries.salesChannel.refetch();
      } catch (error) {
        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("c.channels.common.verification_error_message"),
        });
      }
    },
  },
  apollo: {
    salesChannel: {
      query: GET_SALES_CHANNEL,
      variables() {
        return {
          businessId: this.currentBusinessId,
          salesChannelEnum: channels.FACEBOOK,
        };
      },
      update(response) {
        const channel = response.business.salesChannels[0];
        return {
          username: channel.username,
          verificationStatus: channel.state,
        };
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.image-wrapper {
  background: rgba($color: #dee2e6, $alpha: 0.5);
}
</style>
