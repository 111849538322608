var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preview"},[_c('div',{staticClass:"preview__body"},[_c('div',{staticClass:"preview__element"},[_c('h2',[_vm._v(_vm._s(_vm.$t("c.channels.widget.preview_title")))]),_c('div',{staticClass:"preview__menu"},[(_vm.imageUrl)?_c('div',{staticClass:"preview__cover",style:(("background-image: url(" + _vm.imageUrl + ")"))}):_vm._e(),_c('div',{staticClass:"preview__menu-info",class:{ 'miss-cover': !_vm.imageUrl }},[_c('div',{staticClass:"preview__menu-block"},[_c('h4',[_vm._v(_vm._s(_vm.activeBusiness.name))]),_c('div',{staticClass:"line line--l m-b-5"}),_c('div',{staticClass:"in-line"},[_c('div',{staticClass:"dot m-r-4",style:({ backgroundColor: _vm.primaryColor })}),_c('div',{staticClass:"line line--xs"}),_c('div',{staticClass:"dot m-r-4 m-l-10",style:({ backgroundColor: _vm.primaryColor })}),_c('div',{staticClass:"line line--xs"})]),_c('div',{staticClass:"in-line m-t-10"},[_c('div',{staticClass:"preview__icon",style:({ backgroundColor: _vm.primaryLightColor })},[_c('Icon',{attrs:{"icon":"diamond","color":_vm.primaryColor,"size":11}})],1),_vm._m(0)])]),_c('div',{staticClass:"preview__menu-block in-line-between"},[_c('div',[_c('div',{staticClass:"line line--s line--dark m-b-5"}),_c('div',{staticClass:"in-line"},[_c('div',{staticClass:"dot m-r-4",style:({ backgroundColor: _vm.primaryColor })}),_c('div',{staticClass:"line line--m"})])]),_c('div',{staticClass:"preview__delivery-switcher",style:({ borderColor: _vm.primaryColor })},[_c('div',{style:({
                  color: _vm.primaryColor,
                })},[_vm._v(" "+_vm._s(_vm.$t("c.channels.widget.preview_delivery"))+" ")]),_c('div',{style:({
                  backgroundColor: _vm.primaryColor,
                  color: _vm.secondaryColor,
                })},[_vm._v(" "+_vm._s(_vm.$t("c.channels.widget.preview_pickup"))+" ")])])]),_c('div',{staticClass:"preview__menu-block in-line"},[_c('div',{staticClass:"preview__category-label",style:({
                borderColor: _vm.primaryColor,
                backgroundColor: _vm.primaryColor,
                color: _vm.secondaryColor,
              })},[_vm._v(" "+_vm._s(_vm.$t("c.channels.widget.preview_category"))+" ")]),_c('div',{staticClass:"preview__category-label"},[_vm._v(" "+_vm._s(_vm.$t("c.channels.widget.preview_category"))+" ")]),_c('div',{staticClass:"preview__category-label"},[_vm._v(" "+_vm._s(_vm.$t("c.channels.widget.preview_category"))+" ")]),_c('div',{staticClass:"preview__category-label"},[_vm._v(" "+_vm._s(_vm.$t("c.channels.widget.preview_category"))+" ")])]),_c('div',{staticClass:"preview__menu-block container"},[_c('div',{staticClass:"line line--m line--dark m-b-10"}),_vm._l((4),function(item){return _c('div',{key:item,class:{ 'in-line-between m-b-10': true, half: !_vm.isListLayout }},[_c('div',[(!_vm.isListLayout)?_c('div',{staticClass:"square square--full m-b-5"}):_vm._e(),_c('div',{staticClass:"line line--l line--dark m-b-5"}),_c('div',{staticClass:"line line--xl m-b-5"}),_c('div',{staticClass:"line line--xl m-b-5"}),_c('div',{staticClass:"line line--s line--dark"})]),(_vm.isListLayout)?_c('div',{staticClass:"square"}):_vm._e()])}),(_vm.isListLayout)?[_vm._m(1),_vm._m(2)]:_vm._e(),_c('div',{staticClass:"preview__menu-action"},[_c('div',{staticClass:"preview__add-item",style:({
                  backgroundColor: _vm.primaryColor,
                  color: _vm.secondaryColor,
                })},[_vm._v(" "+_vm._s(_vm.$t("c.channels.widget.preview_basket_action"))+" ")])])],2)])])]),_c('div',{staticClass:"preview__element"},[_c('h2',[_vm._v(_vm._s(_vm.$t("c.channels.widget.preview_popup_title")))]),(_vm.showBubble)?_c('div',{staticClass:"preview__widget-body"},[_c('div',{staticClass:"preview__widget"},[_c('Icon',{staticClass:"preview__close",attrs:{"icon":"cross-widget"}}),_c('h3',{staticClass:"preview__headline"},[_vm._v(_vm._s(_vm.headline))]),_c('p',{staticClass:"preview__message"},[_vm._v(_vm._s(_vm.message))]),_c('div',{staticClass:"preview__bottom"},[_c('button',{style:({
                color: _vm.secondaryColor,
                backgroundColor: _vm.primaryColor,
                borderRadius: _vm.borderRadius,
              })},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]),_vm._m(3)])],1),_c('div',{staticClass:"preview__button"},[(_vm.iconUrl)?_c('img',{staticClass:"preview__image",attrs:{"src":_vm.iconUrl}}):_c('Icon',{staticClass:"preview__image-default",attrs:{"icon":"chatfood-logo","color":"#1258FF","size":31}})],1)]):_c('div',{staticClass:"preview__widget-body"},[_c('button',{staticClass:"preview__order-button",style:({
            color: _vm.secondaryColor,
            backgroundColor: _vm.primaryColor,
            borderRadius: _vm.borderRadius,
          })},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"line line--l line--dark m-b-3"}),_c('div',{staticClass:"line line--m"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"in-line-between m-b-10"},[_c('div',[_c('div',{staticClass:"line line--l line--dark m-b-5"}),_c('div',{staticClass:"line line--xl m-b-5"}),_c('div',{staticClass:"line line--xl m-b-5"}),_c('div',{staticClass:"line line--s line--dark"})]),_c('div',{staticClass:"square"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"in-line-between"},[_c('div',[_c('div',{staticClass:"line line--l line--dark m-b-5"}),_c('div',{staticClass:"line line--xl m-b-5"}),_c('div',{staticClass:"line line--xl m-b-5"}),_c('div',{staticClass:"line line--s line--dark"})]),_c('div',{staticClass:"square"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"preview__powered"},[_vm._v(" We’re ⚡️ by "),_c('span',{staticClass:"preview__powered-chatfood"},[_vm._v("ChatFood")])])}]

export { render, staticRenderFns }