import { generalErrorToast } from "./general-error-toast";
import { IValidateImageConfig, ValidateImageEnum } from "./validate-image";
import { t } from "@/i18n";

export function imageValidationErrorToast(
  imageValidationResult: ValidateImageEnum,
  config: IValidateImageConfig
): void {
  if (imageValidationResult === ValidateImageEnum.MAX_FILE_SIZE) {
    generalErrorToast(
      t("message.image_error.size_error", { size: config.maxFileSizeMB })
    );
    return;
  }

  if (imageValidationResult === ValidateImageEnum.TYPE) {
    generalErrorToast(t("message.image_error.type_error"));
    return;
  }

  if (
    config.maxHeight &&
    config.maxWidth &&
    (imageValidationResult === ValidateImageEnum.MAX_WIDTH ||
      imageValidationResult === ValidateImageEnum.MAX_HEIGHT)
  ) {
    generalErrorToast(
      t("message.image_error.max_dimension_error", {
        width: config.minWidth,
        height: config.minHeight,
      })
    );
    return;
  }

  if (
    config.minHeight &&
    config.minHeight &&
    (imageValidationResult === ValidateImageEnum.MIN_HEIGHT ||
      imageValidationResult === ValidateImageEnum.MIN_WIDTH)
  ) {
    generalErrorToast(
      t("message.image_error.min_dimension_error", {
        width: config.minWidth,
        height: config.minHeight,
      })
    );
    return;
  }

  if (imageValidationResult === ValidateImageEnum.MIN_HEIGHT) {
    generalErrorToast(
      t("message.image_error.min_height_error", { height: config.minHeight })
    );
    return;
  }
  if (imageValidationResult === ValidateImageEnum.MIN_WIDTH) {
    generalErrorToast(
      t("message.image_error.min_width_error", { width: config.minWidth })
    );
    return;
  }
  if (imageValidationResult === ValidateImageEnum.MAX_HEIGHT) {
    generalErrorToast(
      t("message.image_error.max_height_error", { height: config.maxHeight })
    );
    return;
  }
  if (imageValidationResult === ValidateImageEnum.MAX_WIDTH) {
    generalErrorToast(
      t("message.image_error.max_width_error", { width: config.maxWidth })
    );
  }
}
