<template>
  <div class="min-vh-100">
    <div class="sales-channels">
      <div class="sales-channels__left">
        <Sidebar />
      </div>
      <div class="sales-channels__right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/modules/sales-channels/ui/partials/Sidebar.vue";

export default {
  name: "SalesChannelsIndex",
  components: {
    Sidebar,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-channels {
  display: flex;
  min-height: 100vh;

  &__left {
    width: 100%;
    min-width: 240px;
    max-width: 240px;
    border-right: solid 1px #dee2e6;
  }

  &__right {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 676px) {
    display: block;

    &__left {
      max-width: 100%;
      border-right: none;
      border-bottom: solid 1px #dee2e6;
    }
  }
}
</style>
