<template>
  <div>
    <Tabs :routes="tabRoutes" />
    <div class="content-body">
      <router-view />
    </div>
  </div>
</template>

<script>
import Tabs from "@/modules/sales-channels/ui/components/Tabs.vue";

export default {
  name: "FacebookIndex",
  components: {
    Tabs,
  },
  computed: {
    tabRoutes() {
      return [
        {
          name: "sales_channels.facebook.index",
          text: this.$t("c.channels.facebook_connect.tab_profile"),
        },
        {
          name: "sales_channels.facebook.post",
          text: this.$t("c.channels.facebook_connect.tab_post"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.content-body {
  margin: 0 auto;
  max-width: 540px;
  padding: 44px 10px;
}
</style>
